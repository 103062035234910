import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  interval: any;
  timeout: any;
  rememberFlag: boolean;
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseURL: string,
    private _router: Router
  ) {}

  login(body: any): Observable<any> {
    const url = this.baseURL + `auth/signin`;
    return this.http.post<any>(`${url}`, body).pipe();
  }

  requestPwd(email: any): Observable<any> {
    const url = this.baseURL + `auth/requestpwd?email=${email}`;
    return this.http.put<any>(`${url}`, {}).pipe();
  }

  resetPwd(body: any): Observable<any> {
    const url = this.baseURL + `auth/resetpwd`;
    return this.http.put<any>(`${url}`, body).pipe();
  }

  getRefreshToken() {
    const body = { 'refreshToken': this.getRefreshTkn() };
    const url =  this.baseURL + `users/refreshtoken`;
    return this.http.post<any>(`${url}`, body).pipe();
  }

  getNavigationAccess() {
    const url = this.baseURL + `auth/navigation`;
    return this.http.get<any>(`${url}`).pipe();
  }

  authTimeout() {
      if(this.timeout){
        clearTimeout(this.timeout);
      }
      // logout user on 45 minutes of inactivity.
      this.timeout = setTimeout(()=>{
        this.clearSession();
        this.logout();
      }, 1000 * 60 * 45);
  }

  isLoggedIn() {
    const authStatus = this.getAccessTkn() == null ? false : true;
    if (authStatus) {

      window.removeEventListener("mousemove", this.authTimeout.bind(this))
      window.addEventListener("mousemove", this.authTimeout.bind(this))

      if (!this.interval) {
        this.interval = setInterval(() => {
          fetch(`${this.baseURL}auth/refresh-token`, {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken: this.getRefreshTkn() }),
            method: 'POST',
          })
            .then((res) => {
              return res.json();
            })
            .then((json) => {
              this.setSession(false, {
                aTKN: json.token,
                rTKN: json.refreshToken,
              });
            });
        }, 1000 * 60);
      }
    }
    return authStatus;
  }

  getAccessTkn() {
    return (
      localStorage.getItem('atktkgyt') || sessionStorage.getItem('atktkgyt')
    );
  }

  getRefreshTkn() {
    return (
      localStorage.getItem('rtktkgyt') || sessionStorage.getItem('rtktkgyt')
    );
  }

  setSession(flag: any, set: any) {
    if (flag) {
      localStorage.setItem('atktkgyt', set?.aTKN);
      localStorage.setItem('rtktkgyt', set?.rTKN);
    } else {
      sessionStorage.setItem('atktkgyt', set?.aTKN);
      sessionStorage.setItem('rtktkgyt', set?.rTKN);
    }
  }

  logout() {
    this._router.navigateByUrl('/auth/login');
  }

  clearSession() {
    if (localStorage.getItem('atktkgyt') != null) {
      localStorage.removeItem('atktkgyt');
      localStorage.removeItem('rtktkgyt');
      localStorage.removeItem('language');
      sessionStorage.removeItem('atktkgyt');
      sessionStorage.removeItem('rtktkgyt');
      sessionStorage.removeItem('language');
    } else {
      sessionStorage.removeItem('atktkgyt');
      sessionStorage.removeItem('rtktkgyt');
      sessionStorage.removeItem('language');
    }
  }
}
