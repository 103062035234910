<mat-dialog-content>
    <div class="welcome-banner">
      <div fxLayout="column" fxLayoutAlign="center start">
        <span fxLayout="row" class="p-2 m-2 lbl">{{ 'ONBOARDING.WELCOME' | translate }}</span>
        <span fxLayout="row" class="p-2 m-2 email">{{ email }}</span>
        <span fxLayout="row" fxLayoutAlign="center center" class="p-2 m-2">
          <img
            src="assets/images/yt-time.svg"
            alt="rc"
            class="yt-time-image"
            [fxFlex.sm]="{ 'width': '80px', 'height': 'auto' }"
          />
        </span>
      </div>
    </div>
  </mat-dialog-content>
  