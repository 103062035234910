export interface IRoute {
    label: any,
    icon: any,
    url:any,
    access:any,
    children:[] | any,
    routes:[] | any
  }
  
  export interface IState{
    state:IRoute[],
  }

  export interface IFullScreen{
    display:boolean
  }

  export interface ICurrentState{
    access:number,
  }

  export interface IUserDetails{
    name : any;
    isAdmin : boolean;
    firstLogin: boolean;
  }
  
  export interface IBanner{
    display:boolean,
  }
  export interface ILanguage{
    locale:boolean,
  }

  