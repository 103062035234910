import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationService } from '../core/services/navigation/navigation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WelcomeDialogComponent } from '../module/shared/welcome-dialog/welcome-dialog.component';

@Component({
  selector: 'yellow-time-web-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit,AfterViewInit {
  sideBar:boolean;
  isFullScreen=false;
  firstLogin = false;
  name:string;
  constructor(
    private _nService: NavigationService,
    private _dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this._nService.mode$.subscribe(x=>{
      this.isFullScreen = !(x?.display);
    })
    this._nService.user$.subscribe(x=>{
       this.firstLogin = x?.firstLogin ?? false;
       this.name = x?.name;
    });
    
  }

  ngAfterViewInit(): void {
    if(this.firstLogin){
      this.displayWelcomeBanner();
   }
  }

  displayWelcomeBanner() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.backdropClass= 'cdk-overlay-transparent-backdrop';
    dialogConfig.width = '680px';
    dialogConfig.height ='350px';
    dialogConfig.panelClass = 'welcome-dialog';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose= false;
    dialogConfig.data = {
      email: this.name
    }
    this._dialog.open(WelcomeDialogComponent, dialogConfig).afterClosed().subscribe(res => {});
}

  onToggle() {
    this.sideBar=true;
    setTimeout(()=>{
      this.sideBar=false;
    },500);
  }


}
